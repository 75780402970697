<template>
  <v-row justify="center">
    <div class="text-center" ref="confirm">
      <v-dialog v-model="isOpen" persistent max-width="350">
        <v-sheet
          class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block"
          color="blue-grey darken-3"
          dark
        >
          <div class="grey--text text--lighten-1 text-body-2 mb-4" bold>
            {{ title }}
            <br />
            {{ message }}
          </div>

          <v-btn
            :disabled="loading"
            class="ma-1"
            color="red"
            plain
            @click="reject"
          >
            NO
          </v-btn>

          <v-btn
            :loading="loading"
            class="ma-1"
            color="green"
            plain
            @click="accept"
          >
            YES
          </v-btn>
        </v-sheet>
      </v-dialog>
    </div>
  </v-row>
</template>

<script>
export default {
  name: "ConfirmDelete",

  data: () => {
    return {
      resolvePromise: undefined,
      rejectPromise: undefined,
      isOpen: false,
      message: "",
      title: "",
      loading: false,
    };
  },
  methods: {
    reject() {
      this.resolvePromise(false);
      this.isOpen = false;
    },

    accept() {
      this.resolvePromise(true);
      this.isOpen = false;
    },

    show(opts = {}) {
      this.isOpen = true;
      this.title = opts.title;
      this.message = opts.message;
      this.loading = opts.loading;

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
  },
};
</script>
